.footerInputControl{
    background-color: #2E2D2D;
    margin-bottom: 15px !important;
    width: 100%;
    border-radius: 30px;
    color: #ffffff99 !important;
    padding: 15px;
    border: none;
}

.footer {
  background-color: #1D1D1D;
  min-height: 400px;
   font-family: 'DM Sans', sans-serif;
}
.footer a {
  color: #979797;
  font-family: 'DM Sans', sans-serif;   
  font-size: 13px;
}
.footer a:hover {
  color: #fff;  
}
.footer p {
  color: #979797;
  margin-bottom: 4px !important; 
  font-family: 'DM Sans', sans-serif;  
}

.footer h2 {
  color:#ffffff;
  font-weight: 500;
  font-size: 18px;
  /* text-transform: uppercase; */
}

.footer .footer_links ul {
  display: flex;
  list-style: none;
}

.footer .footer_links ul li a {
  color: #fff;
  text-decoration: none;
}

.footer .footer_secondary {  
  background-color: #2f2f2f;
}

.footer .footer_secondary .follow ul {
  display: flex;
  list-style: none;
  justify-content: center;
  align-items: center;
}


.footer .terms_section h6 {
  font-size: 12px !important;
  color: #9e9e9e !important;
  font-weight: 500;
   font-family: 'DM Sans', sans-serif !important; 
   margin-top: 1.5rem;
   letter-spacing: 0.5px;
}

.footer .terms_section p {
  font-size: 12px !important;
   font-family: 'DM Sans', sans-serif !important; 
  color: #97979780 !important;
}

.footer .subscribe_form_btn{
  border-radius: 20px;
  background-color: #faaa53;
  color: #000;
  font-weight: 700;
  font-family: unset;
  margin: 0 auto;
  display: flex;
  padding: 10px 20px;
  min-width: 150px;
}
.footer .subscribe_form_btn:hover{
  background-color: transparent !important;
  color: #faaa53 !important;
  border:1px solid #faaa53 !important
}

@media screen and (max-width: 991px) {
  .footer .footer_links {
    padding: 10px 50px;
  }

  .footer .footer_links ul {
    flex-direction: column;
  }
}

@media screen and (max-width:767px){
  .clubDetails .bannerPatch {    
    width: 100%;    
    border-radius: 25px;
    display: block;
    height: auto;  
  }
  .footer {
    display: none;
  }
}

@media (max-width:768px){
  .footer_secondary p{
    text-align: center !important;
  }
}

/*************  .Footer Css Start*************/